"use client";

import axios from "axios";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import qs from "query-string";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { getEnv } from "@/utils/env/getEnv";
import type { ReactNode } from "react";

const queryClient = new QueryClient();

axios.defaults.baseURL = getEnv("NEXT_PUBLIC_API_ORIGIN");
axios.defaults.withCredentials = true;

axios.defaults.paramsSerializer = (params) => {
  return qs.stringify(params);
};

export function ReactQueryClientProvider({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      {children}
    </QueryClientProvider>
  );
}
