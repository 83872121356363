// convertHexToRgba('#0059ff', 0.5) => "rgba(0, 89, 255, 0.5)"
const convertHexToRgba = (hex: string, opacity: number) => {
  const r = parseInt(`${hex[1]}${hex[2]}`, 16);
  const g = parseInt(`${hex[3]}${hex[4]}`, 16);
  const b = parseInt(`${hex[5]}${hex[6]}`, 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const generateColors = (brandColor = "#0059ff") => {
  return {
    "elevation-surface-default": "#ffffff",
    "elevation-surface-subtle-blue": "#fbfbfd",
    "elevation-surface-subtle": "#f5f5f5",

    "background-neutral-default": "#ffffff",
    "background-neutral-inverse": "#111111",
    "background-neutral-subtle": "#373737",
    "background-neutral-subtler": "#929292",
    "background-neutral-disabled": convertHexToRgba("#111111", 0.3),
    "background-neutral-subtlest": "#f5f5f7",

    "background-brand-default": brandColor,
    "background-brand-hover": "#014fe2",
    "background-brand-pressed": "#003DAF",
    "background-brand-disabled": convertHexToRgba(brandColor, 0.3),
    "background-brand-subtle": convertHexToRgba(brandColor, 0.12),
    "background-brand-subtler": convertHexToRgba(brandColor, 0.08),
    "background-brand-subtlest": convertHexToRgba(brandColor, 0.05),

    "background-input-default": "#ffffff",
    "background-input-disabled": convertHexToRgba("#111111", 0.04),
    "background-input-readonly": "#f8f8fa",

    "background-button-default": "#eeeef2",
    "background-button-subtle": "#d0d0d4",
    "background-button-subtler": "#e1e1e3",
    "background-button-subtlest": "#f5f5f7",
    "background-button-disabled": convertHexToRgba("#eeeef2", 0.6),

    "background-tag-default": "#f5f5f7",
    "background-tag-disabled": convertHexToRgba("#111111", 0.06),

    "context-error": "#ff2437",
    "context-success": "#37a403",
    "context-link": "#1f98ff",

    "text-default": "#111111",
    "text-subtle": "#555555",
    "text-subtler": "#6f6f6f",
    "text-guide": "#6f6f6f",
    "text-subtlest": "#929292",
    "text-disabled": convertHexToRgba("#111111", 0.2),
    "text-inverse": "#ffffff",

    "icon-default": "#111111",
    "icon-subtle": "#6f6f6f",
    "icon-subtler": "#929292",
    "icon-subtlest": "#cfcfcf",
    "icon-disabled": convertHexToRgba("#111111", 0.2),
    "icon-inverse": "#ffffff",

    "border-default": "#cfcfcf",
    "border-subtle": "#e4e4e6",
    "border-subtler": "#ededef",
    "border-disabled": convertHexToRgba("#111111", 0.1),
    "border-hover": convertHexToRgba(brandColor, 0.5),

    "separator-default": "#e4e4e7",

    "blanket-default": convertHexToRgba("#111111", 0.5),
  } as const;
};

export const designSystemColors = generateColors();
