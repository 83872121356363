"use client";

import { ToastBar, Toaster } from "react-hot-toast";
import Image from "next/image";
import { designSystemColors } from "60hz-design-system";
import { cloneElement, isValidElement, ReactElement } from "react";
import toastSuccessIcon from "../../../../assets/icons/ic_toast_positive.svg";
import toastFailIcon from "../../../../assets/icons/ic_toast_negative.svg";

export function Toast() {
  return (
    <Toaster
      toastOptions={{
        duration: 4000,
        style: {
          padding: "14px 20px",
          margin: 0,
          borderRadius: "4px",
          color: designSystemColors["text-inverse"],
        },
        error: {
          icon: (
            <Image
              alt="toast fail"
              src={toastFailIcon}
              width={24}
              height={24}
              className="h-[24px] w-[24px]"
            />
          ),
          style: {
            background: designSystemColors["context-error"],
          },
        },
        success: {
          icon: (
            <Image
              alt="toast success"
              src={toastSuccessIcon}
              width={24}
              height={24}
              className="h-[24px] w-[24px]"
            />
          ),
          style: {
            background: designSystemColors["context-success"],
          },
        },
      }}
    >
      {(toast) => (
        <ToastBar toast={toast}>
          {({ icon, message }) => {
            return (
              <>
                {icon}
                {message && isValidElement(message) && (
                  <div className="c16-semibold px-[8px]">
                    {cloneElement(
                      message as ReactElement<{ className: string }>,
                      {
                        className: "!m-0",
                      },
                    )}
                  </div>
                )}
              </>
            );
          }}
        </ToastBar>
      )}
    </Toaster>
  );
}
